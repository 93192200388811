/**
 * WIPLocaleCodes are locale codes which we don't currently support from
 * a translation perspective, but do support when it comes to setting a default
 * currency code based on `navigator.language`.
 */

export const WIPLocaleCodes = {
  AF: "af", // Afrikaans
  AF_ZA: "af-ZA", // Afrikaans (South Africa)
  EN_ZA: "en-ZA", // English (South Africa)
  ES_CO: "es-CO", // Spanish (Colombia)
  ES_MX: "es-MX", // Spanish (Mexico)
  ZU: "zu", // Zulu
  ZU_ZA: "zu-ZA" // Zulu (South Africa)
};

export const SupportedLocaleCodes = {
  AZ: "az", // Azerbaijani
  BG: "bg", // Bulgarian
  CS: "cs", // Czech
  DA: "da", // Danish
  DE: "de", // German
  EL: "el", // Greek
  EN: "en", // English
  EN_US: "en-US", // English (US)
  ES: "es", // Spanish
  ES_419: "es-419", // Latin American Spanish
  FR: "fr", // French
  FR_CA: "fr-CA", // Canadian French
  // HE: "he", // Hebrew
  ID: "id", // Indonesian
  IT: "it", // Italian
  // JA: "ja", // Japanese
  NB: "nb", // Norwegian Bokmål
  NL: "nl", // Dutch
  PL: "pl", // Polish
  PT: "pt", // Portuguese
  PT_BR: "pt-BR", // Brazilian Portuguese
  RO: "ro", // Romanian
  RU: "ru", // Russian
  SK: "sk", // Slovak
  SV: "sv", // Swedish
  TR: "tr", // Turkish
  UK: "uk", // Ukrainian
  UR: "ur", // Urdu
  ZH_TW: "zh-TW" // Chinese (Taiwan, Traditional)
};

export default SupportedLocaleCodes;
