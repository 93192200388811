import _ from "@/boot/lodash";
import moment from "moment";
import router from "@/router";
import i18n from "@/i18n";
import { Contexts } from "@/models/contexts";
import { DataModules } from "@/store/modules/data/modules";
import type { UserTypes } from "@/data/enums/tokens";
import { GrantTypes } from "@/data/enums/tokens";
import { Methods } from "@/models/methods";
import { UPM_DATA_LAYER } from "@/boot/gtm";
import { SnackbarProgrammatic as $snackbar } from "buefy";
import { AdminRoutes, ClientRoutes } from "@/data/enums/router";
import { BrandConfigKeys } from "@/data/constants";
import { getCookie } from "@/helpers/cookies";
import type { ActionTree, GetterTree, MutationTree } from "vuex";
import type { AxiosError } from "axios";
import type { IAuthAdminState } from "./admin";
import type { IAuthClientState } from "./client";
import type { IAuthGuestState } from "@/store/modules/auth/guest";
import type { IAuthenticate, IAuthProviderConfiguration } from "@/models/auth";
import type { IState } from "@/store";
import type { IToken } from "@/models/token";
import type { Route } from "vue-router";
import { AppEvents, useAppEvents } from "@/composables/appEvents";

const { pushEvent } = useAppEvents();

export interface IAuthState {
  client: IAuthClientState;
  admin: IAuthAdminState;
  guest: IAuthGuestState;
  reAuthPromise: Promise<any> | null;
  refreshTokenPromise: Promise<any> | null;
}

export const tokenParser = {
  access_token: val => val || "",
  created_at: val => _.toNumber(val || `0`),
  expires_in: val => _.toNumber(val || `0`),
  refresh_expires_in: val => _.toNumber(val || `0`),
  refresh_token: val => val || "",
  second_factor_required: val =>
    _.isBoolean(val) ? val : val === "true" ? true : false,
  token_type: val => val || ""
};

export const tokenState = (context: Contexts): IToken => {
  return _.mapValues(tokenParser, (method, key) => {
    return method(_.get(localStorage, `${context}/auth/token/${key}`));
  });
};

const initialState = () => {
  return {
    reAuthPromise: null,
    refreshTokenPromise: null
  };
};

const getters: GetterTree<IAuthState, IState> = {
  sessionExpiryDate:
    () =>
    (token: IToken): Date => {
      const createdAt = _.toNumber(token.created_at);
      const expiresIn = _.toNumber(
        token.refresh_expires_in || token.expires_in
      );
      return new Date((createdAt + expiresIn) * 1000);
    },
  hasExpiredSession:
    (state, getters, rootState) =>
    (context: Contexts): boolean => {
      const token = _.get(state, `${context}.token`, {}) as IToken;
      return (
        !_.isEmpty(token.access_token) &&
        getters.sessionExpiryDate(token) < rootState.utils.time.now
      );
    },
  isImpersonating:
    state =>
    (context: Contexts): boolean => {
      return !!_.get(state[context], `isImpersonated`);
    }
};

let sessionChangedModal;

const actions: ActionTree<IAuthState, IState> = {
  /**
   * @name prepareTokenPayload
   * @desc For certain grant_types, we check and optionally pass a user's GA
   * client id (gaGlobal.vid) which is then stored against their current
   * session. If set, this value is bundled with push events made to the
   * measurement protocol – allowing us to link previous session activity
   */
  prepareTokenPayload: (
    { rootGetters },
    data: IAuthenticate
  ): IAuthenticate => {
    if (
      [
        GrantTypes.GUEST,
        GrantTypes.GUEST_CUSTOMER,
        GrantTypes.COMPLETE_REGISTRATION,
        GrantTypes.PASSWORD,
        GrantTypes.TWOFA
      ].includes(data.grant_type)
    ) {
      const measurement_id = rootGetters["brand/config"][
        BrandConfigKeys.ANALYTICS_GA_MEASUREMENT_ID
      ]?.replace(/^(G-)/, "");
      if (measurement_id) {
        const _ga = getCookie(`_ga`)?.split(".");
        const _ga_session = getCookie(`_ga_${measurement_id}`)?.split(".");
        const ga_client_id = _.compact([_ga?.[2], _ga?.[3]]).join(".");
        const ga_session_id = _ga_session?.[2];
        if (ga_client_id && ga_session_id)
          _.set(data, "meta", { ga_client_id, ga_session_id });
      }
    }
    return data;
  },
  createAccessToken: async (
    { dispatch },
    { data, withAccessToken = false }
  ) => {
    data = await dispatch("prepareTokenPayload", data);
    return new Promise((resolve, reject) => {
      dispatch(
        "api/call",
        {
          method: Methods.POST,
          path: "oauth/access_token",
          requestConfig: {
            data,
            handle401Error: false,
            handle403IPError: false
          },
          callConfig: {
            withAccessToken,
            rawResponse: true
          }
        },
        { root: true }
      )
        .then(response => {
          const token: IToken = response.data;
          token.created_at = moment().unix();
          const twofa = [GrantTypes.TWOFA_ADMIN, GrantTypes.TWOFA].includes(
            response.headers["token-type"] as GrantTypes
          );
          resolve({ token, twofa });
        })
        .catch(reject);
    });
  },
  refreshAccessToken: ({
    state,
    rootState,
    commit,
    dispatch
  }): Promise<IToken | void> => {
    if (!state.refreshTokenPromise) {
      commit(
        "refreshTokenPromise",
        new Promise((resolve, reject) => {
          const context = rootState.context;
          dispatch(
            "api/call",
            {
              method: Methods.POST,
              path: "oauth/access_token",
              requestConfig: {
                handle401Error: false,
                handle403IPError: false,
                data: {
                  grant_type: GrantTypes.REFRESH_TOKEN,
                  refresh_token: state[context]?.token?.refresh_token || ""
                }
              },
              callConfig: {
                withAccessToken: false
              }
            },
            { root: true }
          )
            .then((token: IToken) => {
              token.created_at = Math.round(new Date().getTime() / 1000);
              commit(`setToken`, {
                context,
                token
              });
              return resolve(token);
            })
            .catch(async error => {
              try {
                const is401 = _.get(error, "response.status") === 401;
                const username = _.get(rootState, `user.username`);
                if (is401 && username.length) {
                  await dispatch("tryReAuthentication", context);
                  return resolve();
                } else {
                  throw "";
                }
              } catch {
                await dispatch("handleReAuthFail", context);
                return context === Contexts.GUEST ? resolve() : reject();
              }
            })
            .finally(() => commit("refreshTokenPromise", null));
        }) as Promise<IToken | void>
      );
    }
    return state.refreshTokenPromise as Promise<IToken | void>;
  },
  tryReAuthentication: (
    { state, commit, dispatch, rootState },
    context: Contexts
  ) => {
    // Don't attempt reAuth if guest user
    if (_.get(rootState, "user.is_guest")) return Promise.reject();
    // Commit reAuth promise
    if (!state.reAuthPromise) {
      commit(
        "reAuthPromise",
        dispatch("openSessionExpiredModal", {
          username: _.get(rootState, "user.username"),
          context
        }).finally(() => {
          commit("reAuthPromise", null);
        })
      );
    }
    return state.reAuthPromise;
  },
  openSessionExpiredModal: async ({ dispatch }, props) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      // Otherwise, select account
      const modal = await dispatch(
        "ui/open/windowModal",
        {
          config: {
            width: 480,
            canCancel: ["button"], // !important
            onCancel: () => {
              reject();
            },
            events: {
              success: () => {
                modal.close();
                resolve();
              }
            },
            props,
            customClass: "is-blurred",
            component:
              props.context === Contexts.ADMIN
                ? () =>
                    import(
                      "@/components/app/global/auth/adminSessionExpiredModal.vue"
                    )
                : () =>
                    import(
                      "@/components/app/global/auth/sessionExpiredModal.vue"
                    )
          }
        },
        { root: true }
      );
    }) as Promise<void>;
  },
  handleReAuthFail: async ({ commit, dispatch }, context: Contexts) => {
    commit(`setToken`, {
      context,
      token: {
        unauthorized: true
      }
    });
    if (context === Contexts.GUEST) {
      // Create new guest token
      await dispatch(`${context}/createToken`);
      router
        .push(window.location.pathname + window.location.search)
        .catch(err => err);
    } else {
      router
        .push({
          name:
            context === Contexts.ADMIN
              ? AdminRoutes.LOGOUT
              : ClientRoutes.LOGOUT
        })
        .catch(err => err);
    }
  },
  openSessionChangedModal: async ({ dispatch }, props) => {
    if (sessionChangedModal) sessionChangedModal.close();
    sessionChangedModal = await dispatch(
      "ui/open/windowModal",
      {
        config: {
          width: 480,
          canCancel: [], // !important
          props,
          events: {
            close: () => {
              sessionChangedModal = undefined;
            }
          },
          customClass: "is-blurred",
          component: () =>
            import("@/components/app/global/auth/sessionChangedModal.vue")
        }
      },
      { root: true }
    );
  },
  getToken: async (
    { dispatch, rootGetters },
    data: IAuthenticate
  ): Promise<IToken> => {
    try {
      const tokenResponse: {
        token: IToken;
        twofa: boolean;
      } = await dispatch("createAccessToken", { data });
      if (tokenResponse.twofa) {
        tokenResponse.token = await dispatch("get2faToken", {
          token: tokenResponse.token
        });
      }
      return tokenResponse.token;
    } catch (e) {
      const error = e as AxiosError;
      if (rootGetters["api/is429"](error)) {
        dispatch(
          "ui/open/windowModal",
          {
            config: {
              width: 540,
              component: () =>
                import("@/components/app/global/auth/error429Modal.vue"),
              props: {
                retryAfter:
                  parseInt(_.get(error, "response.headers.retry-after")) || null
              }
            }
          },
          { root: true }
        );
      } else if (
        rootGetters["api/is409"](error) &&
        !!error.response?.data?.error?.data?.org_unverified
      ) {
        const props = { email: data.username };
        dispatch("org/openResendVerificationModal", { props }, { root: true });
      }
      throw error;
    }
  },
  get2faToken: ({ dispatch }, { token }: { token: IToken }) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      // Otherwise, select account
      const modal = await dispatch(
        "ui/open/windowModal",
        {
          config: {
            component: () =>
              import("@/components/app/global/auth/2faModal.vue"),
            width: 400,
            props: { token },
            onCancel: () => {
              reject("cancelled");
            },
            events: {
              success: (twofaToken: IToken) => {
                modal.close();
                resolve(twofaToken);
              }
            }
          }
        },
        { root: true }
      );
    });
  },
  backupToken: (
    { state },
    { context, token }: { context: Contexts; token?: IToken }
  ) => {
    token = token || (state[context]?.token as IToken);
    if (token.access_token)
      _.each(token, (value, key) => {
        if (["actor_id", "actor_type"].includes(key)) return;
        localStorage.setItem(
          `${context}::backup/auth/token/${key}`,
          _.isNil(value) ? "" : value.toString()
        );
      });
    return;
  },
  restoreToken: async ({ commit, dispatch }, context: Contexts) => {
    try {
      const tokenKey = `${context}::backup` as Contexts;
      const token = tokenState(tokenKey);
      commit(`clearLocalStorage`, tokenKey);
      if (!token.access_token) throw Error();
      await dispatch("saveToken", { token, context });
      await dispatch("refreshAccessToken");
      return Promise.resolve(true);
    } catch {
      await dispatch("logout", context);
      return Promise.resolve(false);
    }
  },
  saveToken: (
    { commit, rootState },
    { token, context }: { token: IToken; context: Contexts }
  ) => {
    commit("setToken", { token, context: context || rootState.context });
    return;
  },
  login: async (
    { dispatch, rootGetters },
    {
      context,
      payload,
      trackEvent = true
    }: { context: Contexts; payload: IAuthenticate; trackEvent?: boolean }
  ) => {
    const token: IToken = await dispatch("getToken", payload);

    if (payload.grant_type === GrantTypes.ADMIN) {
      pushEvent(AppEvents.STAFF_USER_AUTHENTICATED, {
        user_id: token.actor_id
      });
    }

    if (payload.grant_type === GrantTypes.PASSWORD) {
      pushEvent(AppEvents.CLIENT_AUTHENTICATED, {
        client_id: token.actor_id
      });
    }

    if (trackEvent)
      // Push 'login' event
      UPM_DATA_LAYER.push({
        event: "login",
        upmind: {
          user_id: token.actor_id
        }
      });

    /**
     * If a 'redirect' instruction is returned whilst in an Upmind client
     * context (eg. my.upmind.com), then we redirect the client to their
     * corresponding Upmind instance and relay all token data.
     */

    if (token.redirect && rootGetters.isUpmindContext) {
      await dispatch("handleRedirectFromUpmindContext", token);
      return Promise.resolve({ isRedirecting: true });
    }

    /**
     * If a 'redirect' instruction is returned whilst in an admin context
     * (likely due to license suspension or termination), we redirect the
     * user back to My.Upmind (client context) and relay all token data.
     */

    if (token.redirect && rootGetters.isAdminContext) {
      await dispatch("handleRedirectToClientContext", token);
      return Promise.resolve({ isRedirecting: true });
    }

    await dispatch("saveToken", { token, context });
    await dispatch(`${context}/handleLogin`);
    return Promise.resolve({ isRedirecting: false });
  },
  relayToken: (
    context,
    {
      token,
      path,
      onPopupBlock = () => {
        $snackbar.open({
          message: i18n.t("_sentence.relay_requires_popups", {
            hostname: window.location.hostname
          }) as string,
          actionText: i18n.t("_action.dismiss") as string,
          type: "is-caution",
          indefinite: true
        });
      }
    }: { token: IToken; path: Route["path"]; onPopupBlock: () => void }
  ) => {
    return new Promise<any>((resolve, reject) => {
      const origin = _.compact([
        (token?.redirect || "").replace(/^https?:/gi, window.location.protocol),
        window.location.port
      ]).join(":");
      const url = [origin, path.replace(/^\//, "")].join("/");
      const spawned = window.open(url, "_blank");
      if (!spawned || spawned.closed || typeof spawned.closed === "undefined") {
        reject();
        onPopupBlock();
      } else {
        resolve({ origin, focus: () => spawned.focus() });
        const onMessage = event => {
          if (event.origin !== origin) return;
          if (_.get(event.data, "source") !== "upmind") return;
          if (_.get(event.data, "event") === "client-ready") {
            const data = _.omit(token, ["redirect"]);
            data["created_at"] = Math.round(new Date().getTime() / 1000);
            spawned.postMessage(
              { source: "upmind", event: "relay-token", data },
              origin
            );
            window.removeEventListener("message", onMessage, false);
          }
        };
        window.addEventListener("message", onMessage);
      }
    });
  },
  openLoginModal: async ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import("@/components/app/client/clientLoginModal.vue"),
          width: 540,
          ...payload
        }
      },
      { root: true }
    );
  },
  openCompleteRegistrationModal: async ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import(
              "@/components/app/client/clientCompleteRegistrationModal.vue"
            ),
          width: 540,
          ...payload
        }
      },
      { root: true }
    );
  },
  openForgottenPasswordModal: async ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          component: () =>
            import("@/components/app/global/auth/forgottenPasswordModal.vue"),
          width: 480,
          ...payload
        }
      },
      { root: true }
    );
  },
  handleRedirectToClientContext: async ({ dispatch }, token: IToken) => {
    const { resolved } = router.resolve({
      name: ClientRoutes.RELAY_TOKEN
    });

    const { origin, focus } = await dispatch("relayToken", {
      token,
      path: resolved.path,
      onPopupBlock: () =>
        dispatch(
          "ui/open/windowModal",
          {
            config: {
              width: 500,
              canCancel: ["escape", "button", "outside"],
              component: () =>
                import(
                  "@/components/app/global/auth/upmindLoginBlockedModal.vue"
                ),
              props: {
                domain: [token.redirect, "login"].join("/"),
                isAdmin: false
              }
            }
          },
          { root: true }
        )
    });

    dispatch(
      "ui/open/windowModal",
      {
        config: {
          width: 500,
          component: () =>
            import("@/components/app/global/auth/upmindLoginSuccessModal.vue"),
          props: {
            domain: [token.redirect, "login"].join("/"),
            url: [origin, "login"].join("/"),
            focus,
            isAdmin: false
          }
        }
      },
      { root: true }
    );
  },
  handleRedirectFromUpmindContext: async ({ dispatch }, token: IToken) => {
    const { resolved } = router.resolve({ name: "relayAdminToken" });
    const { origin, focus } = await dispatch("relayToken", {
      token,
      path: resolved.path,
      onPopupBlock: () =>
        dispatch(
          "ui/open/windowModal",
          {
            config: {
              width: 500,
              canCancel: ["escape", "button", "outside"],
              component: () =>
                import(
                  "@/components/app/global/auth/upmindLoginBlockedModal.vue"
                ),
              props: {
                domain: [token.redirect, "admin/login"].join("/")
              }
            }
          },
          { root: true }
        )
    });
    dispatch(
      "ui/open/windowModal",
      {
        config: {
          width: 500,
          component: () =>
            import("@/components/app/global/auth/upmindLoginSuccessModal.vue"),
          props: {
            domain: [token.redirect, "admin/login"].join("/"),
            url: [origin, "admin/login"].join("/"),
            focus
          }
        }
      },
      { root: true }
    );
  },
  userReady: async ({ dispatch }) => {
    // Register socket
    // await dispatch("socket/register", {}, { root: true });
    return dispatch(
      `data/${DataModules.USER_NOTIFICATIONS}/refreshHasUnread`,
      {},
      { root: true }
    );
  },
  logout: ({ commit, state, dispatch, rootGetters }, context: Contexts) => {
    if (!_.get(state[context], "token.unauthorized")) {
      dispatch(
        "api/call",
        {
          method: Methods.GET,
          path: rootGetters.isAuthenticatedAdminContext
            ? "api/admin/logout"
            : "api/logout"
        },
        { root: true }
      );
    }
    dispatch(
      "data/binList",
      {
        storeModule: DataModules.USER_NOTIFICATIONS,
        scope:
          rootGetters[
            `data/${DataModules.USER_NOTIFICATIONS}/loggedUserScope`
          ]()
      },
      { root: true }
    );
    dispatch(
      "data/binList",
      {
        storeModule: DataModules.USER_NOTIFICATIONS,
        scope:
          rootGetters[
            `data/${DataModules.USER_NOTIFICATIONS}/loggedUserUnreadScope`
          ]()
      },
      { root: true }
    );
    commit(`clearLocalStorage`, context);
    commit(`${context}/reset`);
    commit(`user`, null, { root: true });
    // dispatch(`socket/unsetToken`, {}, { root: true });
    dispatch(`ui/userflow/end`, null, { root: true });
    // Push app event
    pushEvent(
      context === Contexts.ADMIN
        ? AppEvents.STAFF_USER_LOGGED_OUT
        : AppEvents.CLIENT_LOGGED_OUT
    );
    // (Legacy) Fire event + unset 'upmind.user_id'
    UPM_DATA_LAYER.push({
      event: "upmind.logout",
      upmind: {
        user_id: null
      }
    });
    return Promise.resolve();
  },
  endImpersonation: async (
    { rootState },
    { context, closeWindow }: { context?: Contexts; closeWindow?: boolean }
  ) => {
    // Set context if undefined
    context = context || rootState.context;
    for (const property in localStorage) {
      if (property.startsWith(`${context}/auth/`)) {
        localStorage.removeItem(property);
      }
    }
    const isAdminContext = context === Contexts.ADMIN;
    const name = isAdminContext ? AdminRoutes.LOGOUT : ClientRoutes.LOGOUT;
    router.replace({ name }).catch(err => err);
    if (closeWindow) window.close(); // Might NOT work in certain cases
  },
  getAvailableAuthProviders: async (
    { dispatch },
    {
      type,
      username
    }: {
      type: UserTypes;
      username: IAuthenticate["username"];
    }
  ) => {
    return dispatch(
      "api/call",
      {
        method: Methods.GET,
        path: `api/auth_provider_configurations/${type}/${username}`,
        callConfig: {
          withAccessToken: false
        }
      },
      { root: true }
    );
  },
  getExternalAuthRedirect: async (
    { dispatch },
    {
      configId
    }: {
      configId: IAuthProviderConfiguration["id"];
    }
  ) => {
    const return_url = [
      document.location.origin,
      router.currentRoute?.query?.redirect ||
        router.currentRoute?.fullPath ||
        "/admin"
    ].join("");

    return dispatch(
      "api/call",
      {
        method: Methods.GET,
        path: `api/auth_provider_configurations/${configId}/auth_redirect`,
        requestConfig: {
          params: {
            return_url
          }
        },
        callConfig: {
          withAccessToken: false
        }
      },
      { root: true }
    );
  }
};

const mutations: MutationTree<IAuthState> = {
  setToken: (
    state,
    { token, context }: { token: IToken; context: Contexts }
  ) => {
    _.each(token, (value, key) => {
      if (["actor_id", "actor_type"].includes(key)) return;
      localStorage.setItem(
        `${context}/auth/token/${key}`,
        _.isNil(value) ? "" : value.toString()
      );
      state[context].token[key] = _.get(tokenParser, key, () => value)(value);
    });
  },
  updateTokenProperty: (
    state,
    { context, key, value }: { context: Contexts; key: string; value: string }
  ) => {
    state[context].token[key] = _.get(tokenParser, key, () => value)(value);
  },
  reAuthPromise: (state, promise: Promise<any> | null) => {
    state.reAuthPromise = promise;
  },
  refreshTokenPromise: (state, promise: Promise<any> | null) => {
    state.refreshTokenPromise = promise;
  },
  clearLocalStorage: (state, context) => {
    localStorage.removeItem(`${context}/auth/token/access_token`);
    localStorage.removeItem(`${context}/auth/token/created_at`);
    localStorage.removeItem(`${context}/auth/token/expires_in`);
    localStorage.removeItem(`${context}/auth/token/redirect`);
    localStorage.removeItem(`${context}/auth/token/refresh_expires_in`);
    localStorage.removeItem(`${context}/auth/token/refresh_token`);
    localStorage.removeItem(`${context}/auth/token/second_factor_required`);
    localStorage.removeItem(`${context}/auth/token/token_type`);
    localStorage.removeItem(`${context}/auth/token/unauthorized`);
  }
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
  modules: {
    admin: require("./admin").default,
    guest: require("./guest").default,
    client: require("./client").default
  }
};
